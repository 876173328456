* {
  margin: 0;
  padding: 0;
  color: var(--text);
}

:root {
  --background: rgb(13, 17, 23);
  --text: rgb(230, 237, 243);
  --card: rgb(22, 27, 34);
  --button: rgb(33, 38, 45);
  --button-hover: rgb(48, 54, 61);
  --button-primary: rgb(35, 134, 54);
  --button-primary-hover: rgb(46, 160, 67);
  --border: rgb(48, 54, 61);
  --red: #870000;
  --faded-red: rgba(135, 0, 0, 0.2);
  --border: rgb(48, 54, 61);
  --subtle-contrast: rgba(255, 255, 255, 0.02);
  --soft-contrast: rgba(255, 255, 255, 0.05);
  --hard-contrast: rgba(255, 255, 255, 0.1);
  --harder-contrast: rgba(255, 255, 255, 0.2);
  --hardest-contrast: rgba(255, 255, 255, 0.5);
  --hard-fade: rgba(0, 0, 0, 0.5);
  --white: rgb(255, 255, 255);
  --black: rgb(0, 0, 0);
  --red: rgb(134, 1, 0);
  --positive: #00ff00;
  --negative: #ff0000;

  --tabPurple: rgb(182, 111, 247);
  --tabPurpleFaded: rgba(182, 111, 247, 0.4);
  --tabGreen: rgb(113, 192, 131);
  --tabGreenFaded: rgba(113, 192, 131, 0.4);
  --tabOrange: rgb(236, 117, 111);
  --tabOrangeFaded: rgba(236, 117, 111, 0.4);
  --tabBlue: rgb(120, 162, 246);
  --tabBlueFaded: rgba(120, 162, 246, 0.4);
  --tabYellow: rgb(252, 206, 81);
  --tabYellowFaded: rgba(252, 206, 81, 0.4);
}

body {
  background: var(--background);
}

.content {
  color: var(--text);
  padding: 2rem;
  margin: 2rem auto 0;
  max-width: 160ch;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media screen and (max-width: 767px) {
  .content {
    padding: 2rem 1rem !important;
    gap: 2rem;
  }
}

.card {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  /* overflow: hidden; */
  /* box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0); */
  transition: all 0.5s;
  /* opacity: 0; */
  background: var(--card);
  padding: 4rem;
  gap: 4rem;
}

@media screen and (max-width: 1524px) {
  .card:not(.cta-hero) {
    gap: 2rem;
  }
}

#laptop-image {
  width: 50vw;
  height: 100%;
}

#home-screen-image {
  width: 100%;
  max-width: 750px;
  /* Maintain aspect ratio */
  height: auto;
  /* aspect-ratio: 1.5441478439; */
  object-fit: contain;
  box-shadow: 0 0 50px 20px var(--faded-red);
  border: 1px solid var(--border);
  border-radius: 0.5rem;
  flex: 1;
}

@media screen and (max-width: 1024px) {
  #home-screen-image {
    box-shadow: 0 0 25px 10px var(--faded-red);
  }
}

@media screen and (max-width: 1024px) {
  #home-screen-image {
    /* height: unset;
    max-height: unset; */
  }
}

@media screen and (min-width: 1225px) {
  .home-screen-image-mobile {
    display: none !important;
  }
}

@media screen and (max-width: 1224px) {
  .home-screen-image-desktop {
    display: none !important;
  }
}

#hero-image {
  width: 128px;
  height: 128px;
}

@media screen and (max-width: 1024px) {
  .card {
    padding: 2rem;
  }

  #home-screen-image {
    /* width: calc(100vw - 4rem);
    position: relative; */
    /* left: -1rem; */
    /* box-shadow: 0 0 20px 10px var(--faded-red);
    border: 1px solid var(--border);
    border-radius: 0.5rem; */
  }

  #hero-image,
  #inbox-icon {
    width: 4rem !important;
    height: 4rem !important;
    min-width: 4rem !important;
    min-height: 4rem !important;
  }
}

#inbox-icon .cls-1 {
  fill: #cbcbcb;
}

#inbox-icon .cls-1,
#inbox-icon .cls-2 {
  stroke-width: 0px;
}

#inbox-icon .cls-2 {
  fill: #860100;
}

.card:not(.naked) {
  /* .card { */
  border: 1px solid var(--border);
  background: var(--subtle-contrast) !important;
  /* background: var(--card); */
}

.card.visible {
  opacity: 1;
}

.card span {
  line-height: 1.5;
}

.card-image {
  /* border-right: 1px solid var(--border); */
}

.right .card-image {
  border-left: 1px solid var(--border);
}

@media screen and (max-width: 1024px) {
  .card-image {
    /* border: none !important;
    border-bottom: 1px solid var(--border) !important; */
  }
}

.card.right {
  flex-direction: row-reverse;
}

.card > *:not(.naked) {
  width: 50%;
  flex: 1;
}

.card-image {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100% !important;
  width: 100% !important;
  min-height: 450px !important;
  min-width: 300px !important;
  max-height: 450px !important;
  max-width: 300px !important;
  flex: 1 !important;
  box-shadow: 0 0 25px 10px var(--faded-red);
  border: 1px solid var(--border);
  border-radius: 0.5rem;
}
@media screen and (max-width: 500px) {
  .card-image {
    min-height: unset !important;
    min-width: unset !important;
    max-height: unset !important;
    max-width: unset !important;
  }
}

.card-image img {
  height: 100%;
  width: 100%;
}
.header-links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-links a {
  opacity: 0.8;
  text-decoration: none;
  transition: all 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.header-links a:hover {
  opacity: 1;
}

.header-links a .tooltip {
  position: absolute;
  bottom: -2.25rem;
  right: 0;
  font-size: 0.875rem;
  height: 1.5rem;
  background: var(--card);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid var(--border);
  pointer-events: none;
  user-select: none;
  transition: 0.1s;
  opacity: 0;
  width: max-content;
}

.header-wrapper a .tooltip {
  right: unset;
}

.header-links a:hover .tooltip {
  bottom: -2.5rem;
  opacity: 1;
}

.header-links svg {
  fill: currentColor;
  width: 1.5rem;
  height: 1.5rem;
}

.card.right .card-image {
  border-radius: 0 1rem 1rem 0;
}

@media screen and (max-width: 1024px) {
  .card.right .card-image {
    border-radius: 1rem 1rem 0 0;
  }
}

.card .hero-image {
  height: 100px;
  width: 100px;
  transition: 0.2s;
}

.card .hero-logo {
  opacity: 0;
  width: 0;
}

.card.scroll-top .hero-logo {
  opacity: 1;
  width: 216px;
}

.card-content {
  /* padding: 3rem; */
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media screen and (min-width: 767px) {
  .card-content {
    width: 100%;
  }

  .card:not(.cta-hero) .card-content {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .card-content {
    gap: 0.5rem;
  }
}

.card-content h2 {
  font-size: 4rem;
  font-family: "SohneHalfFat";
  margin-bottom: 2rem;
  line-height: 1.25;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 2rem; */
}

.card-content h2 svg {
  height: 8rem;
  /* width: 10rem; */
  /* min-height: 10rem; */
  min-width: 8rem;
  /* fill: currentColor; */
  transition: all 0.2s;
}

.card-content p {
  font-size: 1.125rem;
  max-width: 80ch;
  /* padding-left: 8rem; */
}

.card-content p span {
  display: block;
}

.card:not(.cta-hero) {
  flex-direction: row-reverse;
  overflow: hidden;
}

@media screen and (max-width: 1224px) {
  .card:not(.cta-hero) {
    flex-direction: column-reverse;
    /* position: relative;
    border: 1px solid var(--border); */
    /* padding-bottom: 50%; */
  }

  .card:not(.cta-hero) .card-image {
    /* position: absolute;
    top: 37.5%; */
    /* left: 1rem;
    right: 1rem;
    width: calc(100% - 4rem); */
  }
}

.card:not(.cta-hero) .card-image {
  /* scale: 1.25; */
}

.card:not(.cta-hero) h2 {
  font-size: 3rem;
}

@media screen and (max-width: 1224px) {
  .card {
    flex-direction: column;
  }

  .card.visible {
    opacity: 1;
    /* box-shadow: 0.5rem 0.5rem var(--subtle-contrast),
      -0.5rem -0.5rem var(--subtle-contrast); */
  }

  .card > *:not(.naked) {
    width: inherit;
  }

  .card.right {
    flex-direction: column;
  }

  .card-image {
    /* border-radius: 1rem 1rem 0 0; */
  }

  .card-content h2 {
    font-size: 3rem;
    line-height: 1.125;
  }

  .card-content h2 svg {
    height: 4rem;
    width: 4rem;
    min-height: 4rem;
    min-width: 4rem;
  }

  .card-content p {
    font-size: 1rem;
    padding-left: 0;
  }

  .card:not(.cta-hero) h2 {
    font-size: 2rem;
  }
}

.banner {
  background: var(--tabPurpleFaded);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.banner > * {
  opacity: 0.8;
  font-size: 0.875rem;
}

.hover-link {
  text-decoration: underline;
  transition: all 0.1s;
}

.hover-link:hover {
  opacity: 1;
  cursor: pointer;
  transition-duration: 0.2s;
}

.header-wrapper {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(20px) saturate(180%);
  /* border-bottom: 1px solid var(--border); */
  z-index: 9999;
  /* transition: all 0.2s; */
}

.header {
  max-width: 160ch;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

@media screen and (max-width: 767px) {
  .header {
    padding: 1rem;
  }
}

.header-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 1rem; */
}

.header-actions a,
.header-actions p {
  /* background: var(--soft-contrast); */
  border: 1px solid transparent;
  border-radius: 999rem;
  text-decoration: none;
  opacity: 0.8;
  transition: all 0.1s;
  /* padding: 0.75rem 1.25rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

@media screen and (max-width: 1024px) {
  .header-actions a,
  .header-actions p {
    /* padding: 0.5rem 1rem; */
  }
}

p.status {
  background: var(--soft-contrast);
  padding: 0.5rem 1.25rem;
  border-color: var(--border);
  border-radius: 0.5rem;
  text-decoration: none;
  opacity: 0.8;
  transition: all 0.1s;
  padding: 0.75rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.status-indicator-wrapper {
  display: block;
  width: 6px;
  height: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-indicator {
  display: block;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background: var(--positive);
  animation: pulse 3.3s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.install-now {
  padding: 0 !important;
  background: var(--text) !important;
  /* background: rgb(134, 1, 0) !important; */
  opacity: 0.8 !important;
  transition: 0.1s !important;
  border: 1px solid var(--text) !important;
  padding: 0.5rem 1rem !important;
  color: var(--background);
}

.install-now svg {
  fill: var(--background);
}

.install-now:hover {
  opacity: 1 !important;
  color: var(--background);
  transition-duration: 0.1s;
}

.header-actions a:hover {
  /* background: var(--hard-contrast); */
  opacity: 1;
  transition-duration: 0.2s;
}

.footer-wrapper {
  /* background: var(--soft-contrast); */
  padding: 2rem;
  /* border-top: 1px solid var(--border); */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: relative;
  overflow: hidden;
}

.footer {
  max-width: 160ch;
}

@media screen and (max-width: 767px) {
  .footer-wrapper {
    padding: 2rem 1rem;
  }
}

.footer {
  flex: 1;
  gap: 1rem;
}

@media screen and (max-width: 767px) {
  .footer {
    flex-direction: column;
    /* align-items: flex-start !important; */
  }
}

.footer,
.footer > * {
  display: flex;
  justify-content: space-between;
}

.footer > * {
  align-items: center;
  gap: 0.5rem;
  flex: 1;
}

.footer small {
  font-size: 0.875rem;
}

.footer-links {
  flex-direction: column;
  align-items: flex-start;
  font-weight: bold;
  line-height: 1.5;
  transition: all 0.1s;
}

.footer-links p {
  opacity: 0.8;
  font-size: 0.875rem;
}

.footer-links p:hover {
  opacity: 1;
  cursor: pointer;
  transition-duration: 0.2s;
}

.chrome-web-store {
  font-size: 1.25rem;
  max-height: 3rem;
  height: 100%;
  border-radius: 0.5rem;
  opacity: 0.8;
}

@media screen and (max-width: 767px) {
  .chrome-web-store {
    font-size: 1.125rem;
  }
}

.chrome-web-store:hover {
  cursor: pointer;
}

.legal-links {
  justify-content: center;
  align-self: flex-start;
}

.stripe-badge {
  justify-content: center;
  align-self: flex-end;
}

.legal-links *:not(p) {
  cursor: default;
  color: var(--border);
}

.legal-links .link {
  opacity: 0.5;
  color: var(--text);
  transition: all 0.1s;
}

.legal-links .link:hover {
  opacity: 1;
  cursor: pointer;
  transition-duration: 0.2s;
}

.copyright {
  opacity: 0.5;
  justify-content: flex-end;
  align-self: flex-end;
}

@media screen and (max-width: 767px) {
  .legal-links,
  .stripe-badge,
  .copyright {
    justify-content: flex-start;
    align-self: flex-start;
  }
}

.god-spot-hero {
  height: 100vh;
  position: relative;
  top: 0;
  /* background: var(--soft-contrast); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  overflow: hidden;
}

.icons-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  gap: 2rem;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)); */
  backdrop-filter: blur(2px) saturate(140%);
  /* display: none; */
  /* opacity: 0;
  pointer-events: none; */
}

#background-video {
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1000;
  background-size: cover;
  .card-image {
    border-right: 1px solid var(--border);
  }

  .right .card-image {
    border-left: 1px solid var(--border);
  }

  @media screen and (max-width: 1024px) {
    .card-image {
      border: none !important;
      border-bottom: 1px solid var(--border) !important;
    }
  }
  opacity: 0;
}

#background-video.visible {
  animation: fadeIn 2s ease-in-out forwards;
}

.icons-shadow {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  min-height: 100px;
  min-width: 300px;
  position: relative;
  z-index: 9;
}

.icons-shadow:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  width: 200%;
  height: 200%;
  position: absolute;
  filter: blur(1rem);
  z-index: -1;
}

.icons-shadow > svg {
  width: 100px;
  max-width: 100px;
}

.icons-shadow > svg:nth-child(2) {
  max-width: 50px;
}

.god-spot-hero-masonry-wrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  max-height: 100%;
  animation: infiniteScroll 240s alternate infinite;
  position: relative;
}

@keyframes infiniteScroll {
  0% {
    transform: translateX(calc((50% - 50vw) + 1.5rem));
  }
  100% {
    transform: translateX(calc((-50% + 50vw) - 1.5rem));
  }
}

.god-spot-hero-masonry-wrapper img {
  border-radius: 1rem;
  height: calc((100vh) * 0.2 - 1.25rem);
}

.hero-cta-wrapper {
  margin-top: 2rem;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: center; */
  position: relative;
  gap: 1rem;
  /* margin-bottom: 2rem; */
}

.hero-cta-wrapper p {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  line-height: 2rem;
  max-width: 400px;
  /* text-align: center; */
  text-shadow: 0 0px 1rem black;
}

.hero-cta-wrapper a {
  background: var(--red);
  color: var(--white);
  font-weight: bold;
  font-size: 1rem;
  border-radius: 1rem;
  text-decoration: none;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  transition: all 0.1s;
  box-sizing: content-box;
}

.hero-cta-wrapper a:hover {
  background: rgba(145, 1, 0, 1);
  /* border: 2px solid orange; */
  opacity: 1;
  transition-duration: 0.2s;
}

.hero-cta-wrapper small {
  opacity: 0.5;
}

.roadmap-link,
.footer-email,
.footer-made-with {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  gap: 0.5rem;
}

.roadmap-link {
  margin: 0;
}

.footer-made-with {
  justify-content: flex-end;
}

.footer-email {
  opacity: 0.8;
  transition: all 0.1s;
}

@media screen and (max-width: 767px) {
  .footer-made-with,
  .footer-email {
    justify-content: flex-start;
  }
}

.footer-email a {
  color: var(--text);
  text-decoration: none;
}

.footer-email a:hover {
  cursor: pointer;
}

.footer-email:hover {
  opacity: 1;
  cursor: pointer;
  transition-duration: 0.2s;
}

.icon-background {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in:not(.second) {
  opacity: 0;
  transform: translate(0, 0.5rem);
  transition: all 0.2s;
}

.fade-in:not(.second).visible {
  opacity: 1;
  transform: translate(0);
}

.fade-in.second {
  opacity: 0;
  transform: translate(0.5rem);
  transition-delay: 0.5s;
  transition: all 0.5s;
}

.fade-in.second.visible {
  opacity: 1;
  /* transform: translate(0) scale(1); */
  transform: translate(0);
}

.flow-wrapper {
  /* border: 1px solid var(--border); */
  /* background: var(--subtle-contrast); */
  border-radius: 1rem;
  height: auto;
  margin: 4rem -2rem 0;
}

.scroll-message {
  text-align: center;
  opacity: 0.5;
  display: none;
}

@media screen and (max-width: 767px) {
  .scroll-message {
    display: inline-block;
  }
}

.flow-wrapper img {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .flow-wrapper {
    overflow: auto;
    /* margin: 2rem -2rem 0; */
    margin: 2rem auto 0;
    /* transform: translate(-2rem); */
  }

  .flow-wrapper img {
    /* height: 50vh; */
    width: calc(100% - 4rem);
  }
}

@media screen and (max-width: 767px) {
  .flow-wrapper {
    overflow-y: auto;
    /* height: 50vh; */
    width: calc(100% + 4rem);
    transform: translate(-2rem);
  }

  .flow-wrapper img {
    overflow-y: auto;
    height: 50vh;
    width: auto;
  }
}

@media screen and (min-width: 1024px) {
  .flow-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Modal */
.modal-background,
.modal {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999999;
  transition: all 0.15s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: var(--card);
  margin: 1rem;
  border-radius: 1rem;
  transition-delay: 0.15s;
  overflow: hidden;
  height: calc(100vh - 2rem);
}

.modal-content {
  flex: 1;
  margin: 4rem;
  height: calc(100% - 8rem);
  display: flex;
  overflow: auto;
  position: relative;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .modal-content {
    margin: 4rem 2rem 2rem;
  }
}

.modal-content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-family: "SohneHalfFat";
}

.modal-content p {
  line-height: 1.5;
}

.modal-content .previous-incidents {
  margin-top: 4rem;
  padding: 1rem;
  border: 1px solid var(--border);
  border-radius: 1rem;
  opacity: 0.8;
}

.modal-content .previous-incidents h3 {
  margin-bottom: 1rem;
}

.close-modal {
  background: var(--soft-contrast);
  border: none;
  border-radius: 0.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  transition: all 0.1s;
}

.close-modal:hover {
  background: var(--hard-contrast);
  cursor: pointer;
  transition-duration: 0.2s;
}

.close-modal svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--text);
}

@media screen and (max-width: 1024px) {
  .modal {
    border-radius: 1rem;
  }
}

.card-video-background {
  border-right: 1px solid var(--border);
}

.right .card-video-background {
  border-left: 1px solid var(--border);
}

@media screen and (max-width: 1024px) {
  .card-video-background {
    border: none !important;
    border-bottom: 1px solid var(--border) !important;
  }
}

.mini-cards-wrapper {
  display: flex;
  gap: 2rem;
}

.mini-card {
  border: var(--border);
  flex: 1;
  width: 25%;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
}

.mini-card .card-content {
  padding: 2rem 0 4rem;
  width: auto;
}

.mini-card video,
.mini-card .card-image {
  border: 1px solid var(--border);
  border-radius: 1rem !important;
}

.mini-card h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.mini-card .card-content h2 svg {
  height: 3rem;
  width: 3rem;
  min-height: 3rem;
  min-width: 3rem;
  /* fill: currentColor; */
}

.mini-card .card-content p {
  font-size: 1rem;
}

@media screen and (max-width: 767px) {
  .mini-cards-wrapper {
    flex-direction: column;
  }

  .mini-card {
    width: 100%;
  }
}

.hover-dark {
  transition: 0.1s;
  color: var(--red);
}

.hover-dark:hover {
  transition-duration: 0.2s;
  opacity: 0.8;
  cursor: pointer;
}

/* Customizing the scrollbar track (background) */
::-webkit-scrollbar-track {
  background-color: var(--hard-contrast);
}

/* Customizing the scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: var(--red);
  border-radius: 1rem;
  transition: all 0.1s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  transition-duration: 0.2s;
}

/* Customizing the scrollbar width */
::-webkit-scrollbar {
  width: 0.25rem;
}

/* For Firefox */
* {
  /* scrollbar-width: thin; */
  scrollbar-width: none;
  scrollbar-color: var(--red) var(--hard-contrast);
}

.big-link {
  transition: all 0.1s;
}

.big-link:hover {
  color: var(--red);
}

.card-content h2 svg {
  fill: var(--red);
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  margin-bottom: 2rem;
  opacity: 0.8;
  background: var(--faded-red);
  padding: 1rem;
  border-radius: 1rem;
}

@media screen and (max-width: 767px) {
  .new-card-row {
    flex-direction: column;
  }
}
.stripe-badge .cls-1 {
  fill: #635bff;
}
