@font-face {
  font-family: 'SohneHalfFat';
  src: url('./fonts/TestSohne-Halbfett.otf') format('truetype');
}

@font-face {
  font-family: 'SohneStrong';
  src: url('./fonts/TestSohne-Kraftig.otf') format('truetype');
}

@font-face {
  font-family: 'SohneBook';
  src: url('./fonts/TestSohne-Buch.otf') format('truetype');
}

@font-face {
  font-family: 'SohneLight';
  src: url('./fonts/TestSohne-Leicht.otf') format('truetype');
}

@font-face {
  font-family: 'SohneMonoHalfFat';
  src: url('./fonts/TestSohneMono-Halbfett.otf') format('truetype');
}

@font-face {
  font-family: 'SohneMonoStrong';
  src: url('./fonts/TestSohneMono-Kraftig.otf') format('truetype');
}

@font-face {
  font-family: 'SohneMonoBook';
  src: url('./fonts/TestSohneMono-Buch.otf') format('truetype');
}

@font-face {
  font-family: 'SohneMonoLight';
  src: url('./fonts/TestSohneMono-Leicht.otf') format('truetype');
}

body {
  margin: 0;
  font-family: 'SohneBook', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'SohneMonoStrong', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}
